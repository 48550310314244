import { memo } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { useMediaQuery, useTheme } from '@mui/material'
import { Theme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Phone from '@mui/icons-material/Phone'

interface PhoneContactBoxProps {
  isOpen: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phoneContactBox: {
      maxHeight: 0,
      transition: 'max-height 300ms ease 0s',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    },
    open: {
      maxHeight: '500px',
    },
    container: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    text: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
      color: theme.palette.secondary.main,
    },
    hours: {
      color: theme.palette.grey[500],
      fontSize: '14px',
    },
  }),
)

const PhoneContactBox = ({ isOpen }: PhoneContactBoxProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery<Theme>(theme.breakpoints.down('xs'))

  const { phoneContactBox, open, container, text, hours } = useStyles()

  if (!isMobile) {
    return null
  }

  return (
    <div className={`${phoneContactBox} ${isOpen && open}`}>
      <div className={container}>
        <div className={text}>
          <Typography variant='body1'>Ihre persönliche Beratung</Typography>
          <Typography variant='body1'>0800 433 88 77 66</Typography>
          <time className={hours} dateTime='Mo-Su 08:00-20:00'>
            Täglich von 08 - 20 Uhr
          </time>
        </div>
        <Button color='primary' variant='contained' endIcon={<Phone />}>
          Jetzt kostenlos anrufen
        </Button>
      </div>
    </div>
  )
}

export default memo(PhoneContactBox)
