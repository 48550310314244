import { logError, logStackTrace } from '../log'

export async function executeAsync<Args, R>(method: (...args: Args[]) => R) {
  return new Promise((resolve, reject) => {
    try {
      resolve(method())
    } catch (error) {
      logStackTrace()
      logError(`Async execution failed 😞`)

      reject(error)
    }
  })
}
