/* eslint-disable no-undef */
import { memo } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { useMediaQuery, useTheme } from '@mui/material'
import { Theme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import Lock from '@mui/icons-material/Lock'
import Copyright from '@mui/icons-material/Copyright'

import { getCopyrightText } from '../../utils/getTenantInfo.utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: '#fff !important',
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
      color: theme.palette.grey[500],
      marginTop: theme.spacing(3),
    },
    container: {
      padding: theme.spacing(3),
    },
    containerLarge: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
    flex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    flexLarge: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.grey[600],
      lineHeight: '2rem',
      '&:hover': {
        textDecoration: 'none',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '0.85rem',
      },
    },
    address: {
      marginTop: theme.spacing(1),
    },
    icon: {
      fontSize: '1rem',
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      margin: `0 ${theme.spacing(0.5)}px`,
    },
    caption: {
      display: 'flex',
      [theme.breakpoints.down('lg')]: {
        fontSize: '0.85rem',
      },
    },
  }),
)

interface FooterProps {
  extended: boolean
}

const Footer = ({ extended = true }: FooterProps) => {
  const theme = useTheme()
  const isLarge = !useMediaQuery<Theme>(theme.breakpoints.down('md'))

  const {
    appBar,
    flex,
    flexLarge,
    container,
    containerLarge,
    link,
    address,
    icon,
    text,
    caption,
    iconWrapper,
  } = useStyles()

  const [tenant, street, city] = getCopyrightText()

  const cookiesPreferencesHandler = () => window.Optanon.ToggleInfoDisplay()

  return (
    <AppBar elevation={0} position='static' className={appBar}>
      <Container
        maxWidth='lg'
        className={`${container} ${isLarge ? containerLarge : flex}`}
      >
        <div className={`${flex} ${isLarge && flexLarge}`}>
          <Link
            color='inherit'
            className={`${link} ${text}`}
            href={process.env.NEXT_PUBLIC_FFG_URL_IMPRINT}
            target='_blank'
            variant='body1'
          >
            Impressum
          </Link>
          {isLarge && (
            <Typography color='inherit' variant='body1'>
              &nbsp;•&nbsp;
            </Typography>
          )}
          <Link
            color='inherit'
            className={`${link} ${text}`}
            href={process.env.NEXT_PUBLIC_FFG_URL_PRIVACY}
            target='_blank'
            variant='body1'
          >
            Datenschutz
          </Link>
          {isLarge && (
            <Typography color='inherit' variant='body1'>
              &nbsp;•&nbsp;
            </Typography>
          )}
          <Link
            href='#'
            color='inherit'
            className={`${link} ${text}`}
            onClick={cookiesPreferencesHandler}
            variant='body1'
          >
            Cookie Einstellungen
          </Link>
        </div>
        {extended && (
          <div className={`${isLarge ? flexLarge : address} ${flex}`}>
            <span className={caption}>
              <span className={iconWrapper}>
                <Lock className={icon} color='inherit' />
                <Copyright className={icon} color='inherit' />
              </span>
              <Typography color='inherit' variant='body2' className={text}>
                &nbsp;{`${new Date().getFullYear()} ${tenant}`}
              </Typography>
            </span>
            {isLarge && (
              <Typography color='inherit' variant='body2'>
                &nbsp;•&nbsp;
              </Typography>
            )}
            <Typography className={text} color='inherit' variant='body2'>
              {street}
            </Typography>
            {isLarge && (
              <Typography color='inherit' variant='body2'>
                &nbsp;•&nbsp;
              </Typography>
            )}
            <Typography className={text} color='inherit' variant='body2'>
              {city}
            </Typography>
          </div>
        )}
      </Container>
    </AppBar>
  )
}

export default memo(Footer)
