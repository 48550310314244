import {
  broadcastToParent,
  RegisteredEventName,
} from '@finanzcheck/catalyst-pollard'

export function getElementOffsetY(element: Element) {
  const { top } = element.getBoundingClientRect()

  return (
    top +
    (window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0)
  )
}

export function scrollTo(position: number, isInIframe = false) {
  if (!isInIframe) {
    window.scrollTo(0, position)
    return
  }

  broadcastToParent({
    eventName: RegisteredEventName.scrollTo,
    data: { position },
  })
}

function isInputFocused(element: Element) {
  const input = element.getElementsByTagName('input')?.[0]
  if (!input) {
    return false
  }

  return document.activeElement === input
}

/**
 * If page is loaded in iFrame, `scrollTo` will be published
 */
export function scrollToElement(element: Element, isInIframe = false) {
  if (isInputFocused(element)) {
    return
  }

  if (!isInIframe) {
    element.scrollIntoView()
    return
  }

  scrollTo(getElementOffsetY(element), isInIframe)
}

export function scrollToTop(isInIframe = false) {
  window.requestAnimationFrame(() => {
    scrollTo(0, isInIframe)
  })
}
