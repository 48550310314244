/* eslint-disable no-undef */
import TagManager from 'react-gtm-module'
import { createEventHandler } from '@finanzcheck/catalyst-frameman'
import {
  broadcastToParent,
  Client,
  RegisteredEventName,
} from '@finanzcheck/catalyst-pollard'
import { logInfo } from './log'
import { executeAsync } from './performance'
import { scrollTo } from './window/scroll'

export const TAURINE_IFRAME_NAME = 'loan_application_widget'

export const pollardTaurineClient = new Client({
  target: 'child',
  childName: TAURINE_IFRAME_NAME,
})

export const trackingHandler = createEventHandler(
  (tracking: { affiliate: string }) => {
    TagManager.dataLayer({
      dataLayer: tracking,
    })

    logInfo(`[Pollard] ${RegisteredEventName.tracking}`)
  },
)

function addResizeObserver({ isAsyncData, elemRef, offset }: any = {}) {
  const ro = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    const [{ target }] = entries
    const { scrollHeight } = target
    let height = scrollHeight

    if (isAsyncData) {
      height = document?.querySelector(elemRef)?.offsetHeight + offset
    }

    broadcastToParent({
      eventName: RegisteredEventName.changeViewport,
      data: { height },
    })
  })

  ro.observe(document.documentElement)
}

export const initPollardCommunication = async (options: any = {}) => {
  if (inIframe()) {
    executeAsync(() => {
      addResizeObserver(options)
    })

    // The iframe height should be adjusted by 45 pixels
    // in order to fit the whole content inside iframe w/o scroll
    // eslint-disable-next-line no-unsafe-optional-chaining
    const offsetHeight = document?.documentElement?.offsetHeight + 45
    broadcastDataToLegacyScript(offsetHeight)
  }
}

export const broadcastChildFrameData = () => {
  if (inIframe()) {
    /* 
      Scroll to top of the iframe (Fix for ANCH-1311)
      Reason: The events are NOT broadcasted from child iframe, 
              until and unless the iframe is within the viewport 
    */
    scrollTo(window?.self?.screenTop, true)

    pollardTaurineClient.listenTo(
      RegisteredEventName.changeViewport,
      (prop: any) => {
        broadcastToParent({
          eventName: RegisteredEventName.changeViewport,
          // The iframe height should be adjusted by 120 pixels
          // in order to fit the whole content inside iframe w/o scroll
          data: { height: prop.height + 120 },
        })

        // The iframe height should be adjusted by 170 pixels (in case of legacy smava embed script)
        // in order to fit the whole content inside iframe w/o scroll
        const adjustedHeight = prop.height + 170
        broadcastDataToLegacyScript(adjustedHeight)
      },
    )

    pollardTaurineClient.listenTo(
      RegisteredEventName.scrollTo,
      ({ position }: any) => {
        broadcastToParent({
          eventName: RegisteredEventName.scrollTo,
          data: { position },
        })
      },
    )

    pollardTaurineClient.listenTo(
      RegisteredEventName.redirect,
      ({ url }: any) => {
        broadcastToParent({
          eventName: RegisteredEventName.redirect,
          data: { url },
        })
      },
    )

    pollardTaurineClient.listenTo(
      RegisteredEventName.openWindow,
      ({ url }: any) => {
        broadcastToParent({
          eventName: RegisteredEventName.openWindow,
          data: { url },
        })
      },
    )
  }
}

const broadcastDataToLegacyScript = (height: number) => {
  // Broadcast message to old legacy smava embed script
  window?.parent?.postMessage(`RESIZE:${height}`, '*')

  if (!(TAURINE_IFRAME_NAME in document)) {
    window?.parent?.postMessage('SCROLL_TO:0', '*')
  }
}

const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}
