/* eslint-disable @next/next/no-img-element */
import { memo } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { useMediaQuery, useTheme } from '@mui/material'
import { Theme } from '@mui/material/styles'

import { getLogo } from '../../utils/getTenantInfo.utils'

const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logoLarge: {
      justifyContent: 'start',
    },
    image: {
      height: '20px',
    },
    imageLarge: {
      height: '24px',
    },
  }),
)

const Logo = () => {
  const theme = useTheme()
  const isLarge = !useMediaQuery<Theme>(theme.breakpoints.down('sm'))
  const isMobile = useMediaQuery<Theme>(theme.breakpoints.down('xs'))

  const { logo, logoLarge, image, imageLarge } = useStyles()
  const { path: logoPath, alt: logoAlt } = getLogo()

  return (
    <a
      href='#'
      rel='noopener noreferrer'
      className={`${logo} ${isLarge && logoLarge}`}
    >
      <img
        className={`${isMobile ? image : imageLarge}`}
        src={logoPath}
        alt={logoAlt}
      />
    </a>
  )
}

export default memo(Logo)
